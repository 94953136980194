import Layout from '../../layouts'
import * as React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import {
  Carousel,
  Container,
  ImageContainer,
  ItemImage,
} from '../../components'
import { theme } from '../../assets/theme'
const Podvorie = ({ data, pageContext: context, location }) => {
  const [fullscreenImageIndex, setFullscreenImageIndex] = React.useState(null)
  return (
    <Layout
      menu={context?.menu}
      locale={context?.locale}
      meta={context?.meta}
      location={location}>
      <Container>
        <Gallery>
          {data?.strapiGallery.images.map((image, key) => (
            <SImageContainer onClick={() => setFullscreenImageIndex(key)}>
              <ItemImage
                image={image.localFile.childImageSharp.gatsbyImageData}
              />
            </SImageContainer>
          ))}
        </Gallery>
        <Carousel
          outsideCurrentIndex={fullscreenImageIndex}
          images={data?.strapiGallery?.images}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query GalleryTemplate($locale: String!) {
    strapiGallery(locale: { eq: $locale }) {
      id
      images {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 564, height: 440)
          }
        }
      }
    }
  }
`

const Gallery = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 4rem 0;

  ${() => theme.mqMax('lg')} {
    padding: 2rem 0;
  }
`

const SImageContainer = styled(ImageContainer)`
  height: 300px;
  ${ItemImage} {
    width: 100%;
  }
`

export default Podvorie
